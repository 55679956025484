import { IProposalModel, RequiredOrAdjustedOrAuthorized } from "@/types/models/Proposal";

export default class ProposalService {
  static isProposalFGTS(proposal: IProposalModel) {
    return proposal.bank_loan_configuration?.covenant?.code === "covenant_fgts";
  }

  static isProposalMotorola(proposal: IProposalModel) {
    return proposal.bank_loan_configuration?.covenant?.code === "jazz";
  }

  static getValueByPriority({ authorized, adjusted, required }: RequiredOrAdjustedOrAuthorized) {
    return authorized || adjusted || required;
  }

  static isProposalRefused(code: string) {
    return ["nao_atendido", "cancelado", "recusado"].includes(code);
  }

  static doesProposalHaveContract(code: string) {
    return ["transferencia_finalizada", "transferencia_aguardando"].includes(code);
  }

  static isDone(code: string = "") {
    return ["transferencia_finalizada"].includes(code);
  }

  static isProposalPending(code: string) {
    return code === "status_pendenciado";
  }
}
